<template>
  <div class="app-box">
    <x-scroll-view :bottom="0.66">
      <x-form-box>
        <x-form-input label="姓名" required v-model="xhrData.name"></x-form-input>
        <x-form-select label="职位" :slots="StaffPositonEnum" @change="positionChange" :bindValue="xhrData.positonType"></x-form-select>
        <!-- <x-form-input label="其他职位" v-model="xhrData.otherPositon"></x-form-input> -->
        <template v-if="customerType==1000">
          <x-form-select label="专业" :slots="StaffProfessionEnum" @change="majorChange" :bindValue="xhrData.majorType"></x-form-select>
          <!-- <x-form-input label="其他专业" v-model="xhrData.otherMajor"></x-form-input> -->
        </template>
        <x-form-phone label="固定电话" :phoneCode="xhrData.fixedLineTelephone" @change="phoneChange"></x-form-phone>
        <x-form-input label="手机号码" required type="number" :maxlength="11" v-model="xhrData.telephone"></x-form-input>
        <x-form-date label="生日" @change="birthdayChange" :defaultDate="xhrData.birthday"></x-form-date>
        <x-form-input label="爱好" v-model="xhrData.hobby"></x-form-input>
      </x-form-box>
    </x-scroll-view>
    <x-footer-box :type="type" :isDisabledBtn="isDisabledBtn" @saveEvent="saveClick" @delEvent="delClick"></x-footer-box>
  </div>
</template>

<script>
import formBox from "@/components/formControl/formBox";
import formInput from "@/components/formControl/formInput";
import formSelect from "@/components/formControl/formSelect";
import formDate from "@/components/formControl/formDate";
import formPhone from "@/components/formControl/formPhone";
import footerBox from "@/components/footerBox";
import scrollView from "@/components/scrollView";
import { ajaxBack } from "@/script/mixins/ajaxBack";
import { getStaff, addStaff, updateStaff, deleteStaff } from "@/api/customerCenter";
import { Toast } from "mint-ui";

export default {
  data () {
    return {
      customerType: this.$route.params.customerType,
      isDisabledBtn: true,
      type: this.$route.params.type,
      xhrData: {
        orgId: this.$route.params.orgId,
        name: null,
        positon: null,
        positonType: null,
        otherPositon: null,
        major: null,
        majorType: null,
        fixedLineTelephone: null,
        telephone: null,
        birthday: null,
        hobby: null
      },
      StaffPositonEnum: [{
        flex: 1,
        values: [],
        textAlign: "center",
        defaultIndex: 0
      }],
      StaffProfessionEnum: [{
        flex: 1,
        values: [],
        textAlign: "center",
        defaultIndex: 0
      }]
    };
  },
  mixins: [ajaxBack],
  created () {
    document.title = "";
    this.type == "new" && (document.title = "员工信息");
    this.setEnum();
  },
  methods: {
    getData () {
      getStaff({
        staffId: this.$route.params.orgId
      }).then(xhr => {
        this.xhrData = xhr.data;
        document.title = this.xhrData.name;
      });
    },
    birthdayChange (item) {
      this.xhrData.birthday = item;
    },
    positionChange (item) {
      this.xhrData.positonType = item.code;
      this.xhrData.positon = item.name;
    },
    majorChange (item) {
      this.xhrData.majorType = item.code;
      this.xhrData.major = item.name;
    },
    phoneChange (phone) {
      this.xhrData.fixedLineTelephone = phone;
    },
    setEnum () {
      const configJson = JSON.parse(sessionStorage.getItem("configJson"));
      if (this.customerType == 1000) {
        configJson.dataArray.StaffPositonEnum.map(item => {
          this.StaffPositonEnum[0].values.push({
            code: item.value,
            name: item.desc
          });
        });
      } else {
        Object.keys(configJson.DealerStaffPositonEnum).forEach(key => {
          this.StaffPositonEnum[0].values.push({
            code: key,
            name: configJson.DealerStaffPositonEnum[key]
          });
        });
      }
      Object.keys(configJson.StaffProfessionEnum).forEach(key => {
        this.StaffProfessionEnum[0].values.push({
          code: key,
          name: configJson.StaffProfessionEnum[key]
        });
      });
      this.type == "edit" && this.getData();
    },
    saveClick () {
      if (!this.isDisabledBtn) {
        if (this.xhrData.telephone && this.xhrData.telephone.length < 11) {
          Toast("请输入正确手机号码");
          return;
        }
        if (this.type == "new") {
          addStaff(this.xhrData).then(xhr => {
            this.ajaxBack(xhr, "添加成功！");
          });
        } else {
          updateStaff(this.xhrData).then(xhr => {
            this.ajaxBack(xhr, "修改成功！");
          });
        }
      }
    },
    delClick () {
      deleteStaff({
        staffId: this.xhrData.id
      }).then(xhr => {
        this.ajaxBack(xhr, "删除成功！", -2);
      });
    }
  },
  watch: {
    "xhrData.name" (newVal) {
      this.isDisabledBtn = !newVal || !this.xhrData.telephone;
    },
    "xhrData.telephone" (newVal) {
      this.isDisabledBtn = !newVal || !this.xhrData.name;
    }
  },
  components: {
    [formBox.name]: formBox,
    [formInput.name]: formInput,
    [formSelect.name]: formSelect,
    [formDate.name]: formDate,
    [formPhone.name]: formPhone,
    [footerBox.name]: footerBox,
    [scrollView.name]: scrollView
  }
};
</script>

<style scoped>
</style>

<template>
  <div class="form-input-box">
    <div class="form-box">
      <table class="form-input-table">
        <tr>
          <td>
            <label class="form-label">{{label}}</label>
            <i v-if="required" class="required-txt">*</i>
          </td>
          <td class="t-r">
            <input type="text" maxlength="4" class="form-input form-input-areacode" :disabled="disable" placeholder="区号" v-model="areaCode" @input="mergePhone">
            -
            <input type="text" maxlength="8" class="form-input form-input-phone" :disabled="disable" placeholder="电话" v-model="phone" @input="mergePhone">
            -
            <input type="text" maxlength="5" class="form-input form-input-extension" :disabled="disable" placeholder="分机号" v-model="extension" @input="mergePhone">
          </td>
        </tr>
      </table>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "x-form-phone",
  data () {
    return {
      tel: null,
      areaCode: null,
      phone: null,
      extension: null
    };
  },
  props: {
    label: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    disable: {
      type: Boolean,
      default: false
    },
    phoneCode: {
      type: String,
      default: null
    }
  },
  created () { },
  methods: {
    mergePhone () {
      this.areaCode && (this.areaCode = this.areaCode.replace(/[^\d]/g, ""));
      this.phone && (this.phone = this.phone.replace(/[^\d]/g, ""));
      this.extension && (this.extension = this.extension.replace(/[^\d]/g, ""));
      this.tel = `${this.areaCode || ""}-${this.phone || ""}-${this.extension || ""}`;
      this.$emit("change", this.tel);
    }
  },
  watch: {
    phoneCode (newVal) {
      if (newVal) {
        this.isLoad = false;
        const phoneArr = newVal.split("-");
        this.areaCode = phoneArr[0];
        this.phone = phoneArr[1];
        this.extension = phoneArr[2];
      }
    }
  },
  components: {}
};
</script>

<style scoped>
.form-input-box {
  display: flex;
  align-items: center;
  padding: 0.16rem 0;
  border-bottom: solid 0.01rem #f6f8fb;
}
.form-box {
  flex: 1;
}
.form-input-table {
  width: 100%;
}
.form-label {
  font-size: 0.14rem;
  color: #737885;
}
.form-input-areacode {
  width: 0.4rem;
}
.form-input-phone {
  width: 0.8rem;
}
.form-input-extension {
  width: 0.4rem;
}
.form-input {
  text-align: right;
  border: 0;
  font-size: 0.14rem;
  color: #30353d;
}
.form-input::-webkit-input-placeholder {
  color: #afb2b9;
}
.required-txt {
  margin-left: 0.04rem;
  font-size: 0.14rem;
  color: #f75959;
}
.t-r {
  text-align: right;
  font-size: 0.14rem;
  color: #99a0aa;
}
</style>
